export enum AvatarSource {
  FACEBOOK = "facebook",
  GOOGLE = "google",
  TWITTER = "twitter",
  VKONTAKTE = "vkontakte",
  SKYPE = "skype",
  GRAVATAR = "gravatar",
  GITHUB = "github",
  CUSTOM = "custom",
  INITIALS = "initials",
  VALUE = "value"
}
